<div class="flex items-center gap-4" *ngIf="isWalletsLoaded">
  <ng-container *ngIf="!environment.settings.disablePurchases">
    <app-subscription-menu-dropdown
      [buttonText]="buttonText"
      [planText]="planText"
      [isSubscribed]="isSubscribed"
      [subscriptionWallet]="subscriptionWallet"
      [showPremiumButton]="showPremiumButton"
      [walletFeatures]="walletFeatures"
    ></app-subscription-menu-dropdown>
  </ng-container>

  <div
    *ngIf="personalWallet"
    class="font-chakra flex rounded py-1.5 px-3 bg-black bg-black-transparent justify-between items-center text-white-primary"
  >
    <svg-icon
      src="assets/icons/dojo-coins.svg"
      alt="dojo-coins"
      [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
      class="mr-2"
    />
    <span class="font-chakra text-sm">{{ personalWallet.balance ?? 0 }}</span>
  </div>
  <div
    *ngIf="freeWallet"
    class="flex rounded px-3 py-1.5 bg-black-transparent justify-between text-white-primary items-center"
  >
    <svg-icon
      [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
      src="assets/icons/love.svg"
      class="mr-2"
    ></svg-icon>
    <span class="font-chakra text-sm">{{ freeWallet.balance ?? 0 }}</span>
  </div>
</div>
