import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { OnboardingModalComponent } from "@components/modals/onboarding-modal/onboarding-modal.component";
import { NavigationMenuComponent } from "@components/navigation-menu/navigation-menu.component";
import { NotificationPopupComponent } from "@components/notification-popup/notification-popup.component";
import { environment } from "@environments/environment";
import { GenericResp } from "@interfaces/generic-resp";
import { GeneralNotif } from "@interfaces/notification";
import { OnboardingModalContent } from "@interfaces/onboarding";
import { User, UserData } from "@interfaces/user";
import { DialogRef, DialogService } from "@ngneat/dialog";
import { AuthService } from "@services/auth/auth.service";
import { CompanyService } from "@services/company.service";
import { FeatureFlagsService } from "@services/feature-flags.service";
import { MixpanelService } from "@services/mixpanel.service";
import { NavBarService } from "@services/nav-bar.service";
import { NotificationsService } from "@services/notifications.service";
import { ProfileService } from "@services/profile.service";
import { RolesService } from "@services/roles.service";
import { ToastMessageService } from "@services/toast-message.service";
import { UtilitiesService } from "@services/utilities.service";
import { BehaviorSubject, filter, first } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private dialog: DialogService = inject(DialogService);
  activatedTab = { index: 1 } as any;
  readonly backendAccount = environment.backend.account;
  // readonly backendCyberlab = environment.backend.cyberlab;

  accountTabsCompanyManager = [
    {
      id: "1",
      name: $localize`:@@organization:Organization`,
      link: "/company",
      iconPath: "assets/icons/company.svg",
    },
    {
      id: "6",
      name: $localize`:@@membersSection:Members`,
      link: "/company/members",
      iconPath: "assets/icons/user-icon.svg",
    },
    {
      id: "2",
      name: $localize`:@@team:Team`,
      link: "/company/groups",
      iconPath: "assets/icons/group-user.svg",
    },
    {
      id: "3",
      name: $localize`:@@classrooms:Classrooms`,
      link: "/company/classrooms",
      iconPath: "assets/icons/group.svg",
    },
    {
      id: "4",
      name: $localize`:@@wallet:Wallet`,
      link: "/company/wallet",
      iconPath: "assets/icons/wallet.svg",
    },
    {
      id: "5",
      name: $localize`:@@profile:Profile`,
      link: "/profile",
      iconPath: "assets/icons/profile1.svg",
    },
  ];
  accountTabsUser = [
    {
      id: "1",
      name: $localize`:@@dashboard:Dashboard`,
      link: "home",
      iconPath: "assets/icons/dashboard.svg",
    },
    {
      id: "2",
      name: $localize`:@@team:Team`,
      link: "/user/group",
      iconPath: "assets/icons/group-user.svg",
    },
    {
      id: "3",
      name: $localize`:@@wallet:Wallet`,
      link: "/user/wallet",
      iconPath: "assets/icons/wallet.svg",
    },
    {
      id: "4",
      name: $localize`:@@subscribe:Subscribe`,
      link: "/subscribe",
      iconPath: "assets/icons/upgrade.svg",
    },
    {
      id: "5",
      name: $localize`:@@profile:Profile`,
      link: "/profile",
      iconPath: "assets/icons/profile1.svg",
    },
  ];
  accountTabsCompanyMember = [
    {
      id: "1",
      name: $localize`:@@dashboard:Dashboard`,
      link: "home",
      iconPath: "assets/icons/dashboard.svg",
    },
    {
      id: "2",
      name: $localize`:@@team:Team`,
      link: "/user/group",
      iconPath: "assets/icons/group-user.svg",
    },
    {
      id: "3",
      name: $localize`:@@wallet:Wallet`,
      link: "/user/wallet",
      iconPath: "assets/icons/wallet.svg",
    },
    {
      id: "4",
      name: $localize`:@@profile:Profile`,
      link: "/profile",
      iconPath: "assets/icons/profile1.svg",
    },
  ];

  currentRoute!: string;
  showNavbar = false;
  showTabs = false;

  navigationDropDownOn = false;
  loading = true;
  user!: User | null;
  isUserLoaded = false;
  userRoles!: string[];
  accountTabs!: any[];
  isSubscriptionPage = false;

  isCheckoutLoading = false;

  notifications: any = [];
  notificationsLastPage = 1;
  notificationNumber = 0;

  showPremiumButton = false;

  notificationInterval: any;
  shouldStopNotification = false;

  subscription: any;
  showWalletNav = false;

  onboardContentUser: OnboardingModalContent[] = [
    {
      id: 1,
      title: $localize`:@@welcomeToSecDojo:Welcome to ${environment.settings.appName},`,

      description: $localize`:@@welcomeToSecDojoDescription:Choose from a variety of tracks: Offensive, Defensive and AppSec. Learn in Cyberlearn, practice in Cyberlab, and grow your career with us with CTFs, certifications and our Hiring Hub!`,
    },
    {
      id: 2,
      title: $localize`:@@YouHaveFreeCoins:You have 300 coins in your free wallet!`,
      description: $localize`:@@YouHaveFreeCoinsDescription:You can get started right away using your free wallet to purchase labs. Additionally, you can enroll in some courses for free!`,
    },
    {
      id: 3,
      title: $localize`:@@EarnMoreCoins:Earn more coins by completing labs`,
      description: $localize`:@@EarnMoreCoinsDescription:Every time you complete a lab, you'll earn coins equivalent to 20% of your score, which you can use to access more labs.`,
    },
  ];

  onBoardCompanyMemberContant: OnboardingModalContent[] = [
    {
      id: 1,
      title: $localize`:@@welcomeToSecDojo:Welcome to ${environment.settings.appName},`,
      description:
        "Choose from a variety of tracks: Offensive, Defensive and AppSec. Learn in Cyberlearn, practice in Cyberlab, and grow your career with us with CTFs, certifications and our Hiring Hub!",
    },
    {
      id: 2,
      title: $localize`:@@howCanYouGetCoursesAndLabs:How can you get courses and labs?`,
      description:
        "You can request the ones you're interested in, and your organization manager will grant you access. Alternatively, ask them for a wallet refill, to make purchases yourself.",
    },
  ];
  firstSubscriptionB2BMember: OnboardingModalContent[] = [
    {
      id: 1,
      title: $localize`:@@welcomeToSecDojo:Welcome to ${environment.settings.appName},`,

      description: $localize`:@@SubscriptionDescription:Choose from a variety of tracks: Offensive, Defensive and AppSec. Learn in Cyberlearn, practice in Cyberlab, and grow your career with us with CTFs, certifications and our Hiring Hub!`,
    },
    {
      id: 2,
      title: "How can you get courses and labs?",
      description: $localize`:@@SubscriptionDescription:Each month, your subscription wallet is filled with a fixed amount of coins. Use these to access labs in Cyberlab. Additionally, you can enroll in any course in Cyberlearn.`,
    },
  ];

  modulesAccess: any = {
    cyberlearnAccess: true,
    cyberlabAccess: true,
    eventAccess: true,
    cyberawarenessAccess: false,
    certificationAccess: false,
    hiringAccess: false,
    classroomAccess: false,
  };

  companyDetails: any = null;

  co_branding!: number;
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly navBar: NavBarService,
    private readonly rolesService: RolesService,
    private readonly mixPanelService: MixpanelService,
    private readonly notificationService: NotificationsService,
    private readonly userService: ProfileService,
    private readonly utilitiesService: UtilitiesService,
    private readonly featureFlagService: FeatureFlagsService,
    private readonly companyService: CompanyService
  ) {
    this.featureFlagService.loadFeatureFlags("public");
    this.route.queryParams.subscribe((params) => {
      if (params["id"] && params["status"] && params["token"]) {
        this.isCheckoutLoading = true;
      }
    });
    this.mixPanelService.init();
    this.authService.currentUser$.subscribe((user: UserData | null) => {
      if (!user) return;

      this.user = user.user;
      this.co_branding = user.user.company
        ? user.user.company.settings.co_branding
        : 0;

      this.featureFlagService.loadFeatureFlags("authenticated").add(() => {
        if (this.user) {
          this.modulesAccess = this.utilitiesService.getModulesAccess(
            this.user
          );
          // if (!this.modulesAccess.classroomAccess) {
          //   this.accountTabsCompanyManager =
          //     this.accountTabsCompanyManager.filter((tab) => tab.id !== "3");
          // }
          if (
            this.user.roles.includes("company-member") ||
            this.user.roles.includes("user")
          ) {
            this.showWalletNav = true;
          }
          this.isUserLoaded = true;
          this.subscription = this.user.subscription;
          this.mixPanelService.identifyUser(this.user);
          this.getNotifications();
          this.refreshNotificationsInterval();
        }
      });
    });
    this.rolesService.currentUserRoles$.subscribe((roles: string[]) => {
      this.userRoles = roles;
      if (this.userRoles.includes("company-manager")) {
        this.accountTabs = this.accountTabsCompanyManager;
      } else if (this.userRoles.includes("user")) {
        this.accountTabs = this.accountTabsUser;
        if (environment.settings.disablePurchases) {
          // Remove the subscribe tab
          this.accountTabs = this.accountTabs.filter((tab) => tab.id !== "4");
        }
      } else if (this.userRoles.includes("company-member")) {
        this.accountTabs = this.accountTabsCompanyMember;
      }

      if (this.userRoles.includes("user")) {
        this.showPremiumButton = true;
      }
      // if (
      //   this.userRoles.includes("company-member") ||
      //   this.userRoles.includes("user")
      // ) {
      //   this.showWalletNav = true;
      // }
      this.accountTabs;
    });
    this.navBar.loading$.subscribe(
      (flag: boolean) =>
        setTimeout(() => {
          this.loading = flag;
        }) // Patches ExpressionChangedAfterItHasBeenCheckedError bug
    );

    /* Get user data only after getting permission from either
      ActivatedRoute.queryParams subscription or loginSSO subscription */
    this.shouldRefreshUserData$.subscribe((_: boolean) =>
      this.authService.refreshUserData()
    );

    /* Since this is the SSO front, we must trigger a user data refresh, so if there is
       a user token in the localstorage, we get a new one, and if there's none we redirect him to login
    */
    this.shouldRefreshUserData.next(true);
    this.getUser(); // Get user data from AuthService when it's available
  }

  //temprorly just for display purposes (will be replaced by roles and authentification logic)

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.showNavbar = !this.isExternalRoute();
        this.showTabs = !this.isExternalRoute();
        this.isSubscriptionPage = this.currentRoute.includes("subscribe");
      }
    });
  }

  getCompanyDetails() {
    this.companyService.getCompanyDetailsV3();
  }

  // showSubscriptionMenu() {
  //   if (environment.settings.disablePurchases) {
  //     return;
  //   }
  //   const dialogRef = this.dialog.open(SubscriptionMenuComponent, {
  //     width: "300px",
  //     windowClass: "navigationMenuPopup",
  //   });

  //   dialogRef.afterClosed$.subscribe((result) => {
  //     if (result) {
  //       this.router.navigate(["/subscribe"]);
  //     }
  //   });
  // }

  logout() {
    this.authService.logout();
  }

  getNotifications() {
    if (
      this.user &&
      this.user.is_disabled !== null &&
      this.user.confirmed !== null
    ) {
      if (
        this.user.is_disabled ||
        !this.user.confirmed ||
        (this.user.company_id &&
          this.user.company_confirmation_status !== "approved")
      ) {
        return;
      }
    }

    this.notificationService
      .getSpecialNotifications(false, 1)
      .subscribe((resp: GenericResp) => {
        this.notifications = resp.data.data;
        this.notificationNumber = resp.data.total;
        this.notificationsLastPage = resp.data.last_page;
        this.notifications = this.notifications.map(
          (notification: GeneralNotif) => {
            notification.data = JSON.parse(notification.data);
            if (notification.path) {
              notification.path = this.translateNotificationPath(notification);
            }
            notification.content = this.stringTemplateParser(
              notification.template,
              notification.data
            );
            return notification;
          }
        );
      });
  }

  refreshNotificationsInterval() {
    this.notificationInterval = setInterval(() => {
      if (!this.user) {
        clearInterval(this.notificationInterval);
        return;
      }
      this.getNotifications();
    }, 900000);
  }

  openNotificationPopup() {
    this.updateLastNotificationCheck();
    this.dialog.open(NotificationPopupComponent, {
      data: {
        notifications: this.notifications,
        lastPage: this.notificationsLastPage,
        notificationNumber: this.notificationNumber,
      },
      width: "440px",
      windowClass: "notificationPopup",
    });
  }

  updateLastNotificationCheck() {
    this.notificationService.updateLastNotificationCheck().subscribe(() => {
      this.notificationNumber = 0;
    });
  }

  showNavigationMenu() {
    const dialogRef = this.dialog.open(NavigationMenuComponent, {
      width: "300px",
      windowClass: "navigationMenuPopup",
    });
  }

  changeTabs(data: any) {
    this.activatedTab = data;
  }

  readonly document = document;
  readonly environment = environment;
  readonly window = window;
  private onboardingTriggered = false;
  private subscriptionOnboardTriggered = false;
  private shouldRefreshUserData = new BehaviorSubject<boolean>(false);
  private shouldRefreshUserData$ = this.shouldRefreshUserData
    .asObservable()
    .pipe(filter((flag: boolean) => flag));

  switchMenu() {
    if (!this.navigationDropDownOn) {
      setTimeout(() => {
        this.navigationDropDownOn = !this.navigationDropDownOn;
      }, 50);
    }
  }

  private getUser() {
    this.authService.currentUser$
      // .pipe(
      //   // Filter out null values
      //   filter((userData): userData is UserData => userData !== null),
      //   // Take the first non-null value, this to avoid re executing this code  a lot of times because we only want to trigger modal opening once
      //   first(),
      //   this turns out to give a problem with reset onboarding feature;
      //   it doesn't subscribe for the newly refreshed data that comes from getAuthenticatedUser()
      .subscribe((userData: UserData | null) => {
        if (userData) {
          this.user = userData.user;
          if (!this.onboardingTriggered) {
            //more simple solution for modals triggered twice
            if (
              this.user.onboarding_data.first_login === 0 &&
              this.user.confirmed
            ) {
              this.checkRoleBasedOnboardingModal(this.user);
            }
          }
          if (this.user.roles.includes("company-member")) {
            if (
              this.user.subscription &&
              this.user.onboarding_data.company_subscribed_notified === 0 &&
              !this.subscriptionOnboardTriggered
            ) {
              this.openFirstSubscriptionModals(
                this.user,
                this.firstSubscriptionB2BMember
              );
              this.subscriptionOnboardTriggered = true;
            }
          }

          if (this.user.roles.includes("company-manager")) {
            this.getCompanyDetails();
          }
          this.onboardingTriggered = true;
          this.companyService.companyDetails$.subscribe((details: any) => {
            this.companyDetails = details;
          });
        }
      });
  }
  checkRoleBasedOnboardingModal(user: User): void {
    if (user.roles.includes("company-member")) {
      this.openOnboardingPopups(user, this.onBoardCompanyMemberContant);
    } else if (user.roles.includes("user")) {
      this.openOnboardingPopups(user, this.onboardContentUser);
    }
  }

  openOnboardingPopups(user: User | null, content: OnboardingModalContent[]) {
    const onboardingModalRef: DialogRef = this.dialog.open(
      OnboardingModalComponent,
      {
        width: "471px",
        data: {
          user,
          content,
          type: "firstLogin",
        },
      }
    );

    onboardingModalRef.afterClosed$.subscribe((result: boolean) => {
      this.userService.updateOnboarding().subscribe({
        next: (data: any) => {
          this.updateFirstLoginLocally();
        },
      });
    });
  }
  openFirstSubscriptionModals(
    user: User | null,
    content: OnboardingModalContent[]
  ) {
    const onboardingModalRef: DialogRef = this.dialog.open(
      OnboardingModalComponent,
      {
        width: "471px",
        data: {
          user,
          content,
          type: "firstSubscriptionB2b",
        },
      }
    );

    onboardingModalRef.afterClosed$.subscribe((result: boolean) => {
      this.userService.updateSubscriptionOnboarding().subscribe({
        next: (data: any) => {
          this.updateFirstSubscriptionLocally();
        },
      });
    });
  }

  updateFirstLoginLocally() {
    if (this.user) this.user.onboarding_data.first_login = 1;
    // Get user from localStorage
    const userData = localStorage.getItem("currentUser");
    if (userData) {
      const user: UserData = JSON.parse(userData);
      user.user.onboarding_data.first_login = 1;
      localStorage.setItem("currentUser", JSON.stringify(user));
    }
  }
  updateFirstSubscriptionLocally() {
    if (this.user) this.user.onboarding_data.company_subscribed_notified = 1;
    // Get user from localStorage
    const userData = localStorage.getItem("currentUser");
    if (userData) {
      const user: UserData = JSON.parse(userData);
      user.user.onboarding_data.company_subscribed_notified = 1;
      localStorage.setItem("currentUser", JSON.stringify(user));
    }
  }
  isExternalRoute() {
    return (
      window.location.href.includes("login") ||
      window.location.href.includes("register") ||
      window.location.href.includes("social/callback") ||
      window.location.href.includes("password/forget") ||
      window.location.href.includes("password/reset") ||
      window.location.href.includes("mobile-redirect") ||
      window.location.href.includes("account-issue")
    );
  }

  isRegisterRoute() {
    return window.location.href.includes("register");
  }

  stringTemplateParser(expression: any, valueObj: any) {
    const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
    if (!expression) {
      return "";
    }
    const text = expression.replace(
      templateMatcher,
      (substring: any, value: any, index: any) => {
        value = valueObj[value];
        return value;
      }
    );
    return text;
  }
  translateNotificationPath(notification: any) {
    if (notification.path == "profile_wallet_page") {
      return "/user/wallet";
    }

    return notification.path;
  }
}
