import { Component, Input, OnInit, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { WalletService } from "@services/wallet.service";
import { UserWallet, WalletsData } from "@interfaces/wallet";
import { User, UserData } from "@interfaces/user";
import { AuthService } from "@services/auth/auth.service";
import { DialogService } from "@ngneat/dialog";
import { environment } from "@environments/environment";
import { Router } from "@angular/router";
import { SubscriptionMenuDropdownComponent } from "../subscription-menu-dropdown/subscription-menu-dropdown.component";

@Component({
  selector: "app-wallet-nav",
  standalone: true,
  imports: [
    CommonModule,
    AngularSvgIconModule,
    SubscriptionMenuDropdownComponent,
  ],
  templateUrl: "./wallet-nav.component.html",
  styleUrls: ["./wallet-nav.component.scss"],
})
export class WalletNavComponent implements OnInit {
  private dialog: DialogService = inject(DialogService);

  wallets: UserWallet[] = [];
  user!: User | undefined;

  isWalletsLoaded = false;

  freeWallet: any = null;
  personalWallet: any = null;

  @Input() showPremiumButton!: boolean;
  subscriptionWallet!: any;

  planText = $localize`:@@elevateYourTrainingLevel:Elevate your training with expert scenarios and top-tier security!`;

  subscription: any;
  isSubscribed = false;
  buttonText = $localize`:@@upgradeNow:Upgrade Now!`;
  walletFeatures!: any;
  readonly environment = environment;

  constructor(
    private walletService: WalletService,
    private authService: AuthService,
    private router: Router
  ) {
    // this.authService.currentUser$.subscribe((userData: UserData | null) => {
    //   this.user = userData?.user;
    // });

    this.authService.currentUser$.subscribe((userData: any) => {
      this.user = userData?.user;
      if (this.user) {
        this.subscription = userData.user.subscription;

        if (this.subscription) {
          this.walletFeatures = JSON.parse(this.subscription.features);
          this.isSubscribed = true;
          this.planText =
            $localize`:@@youAreSubscribed:You are subscribed to the ` +
            this.subscription.plan +
            $localize`:@@enjoyYourTraining: plan. Enjoy your training!`;
          this.buttonText = $localize`:@@viewPlan:View Plan`;
        }
      }
    });
  }

  getWallets() {
    this.walletService.getUserWallets().subscribe((resp: WalletsData) => {
      this.isWalletsLoaded = true;

      if (resp.DEFAULT) {
        this.personalWallet = {
          type: "personal",
          balance: resp.DEFAULT.balance,
        };
      }

      if (resp.FREE) {
        this.freeWallet = {
          type: "fidelity",
          balance: resp.FREE.balance,
        };
      }

      if (resp.SUBSCRIPTION) {
        this.subscriptionWallet = {
          type: "subscription",
          balance: resp.SUBSCRIPTION.balance,
        };
      }
    });
  }

  ngOnInit(): void {
    this.getWallets();
  }
}
